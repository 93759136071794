import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  mapOptions: google.maps.MapOptions = {
    center: { lat: 38.43084751699419, lng: 27.168782722094722 },
    zoom : 15
 }
 marker = {
    position: { lat: 38.43084751699419, lng: 27.168782722094722 },
 }
  constructor() { }

  ngOnInit(): void {
  }

}
