import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ozgur-net';
  isVisible = true;
  popupImage = 'assets/29ekimw.jpg';
  mpopupImage = 'assets/29ekim.jpg';
  collapsed = true;
  innerWidth: number;
  mobileModalVisible:boolean;
  modalVisible:boolean;
  
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth <=576) {
      this.mobileModalVisible = false;
    } else {
      this.modalVisible = false;
    }
  }

  onCancel() {
    this.mobileModalVisible = false;
    this.modalVisible =false;

  }
}
