<div class="col-10 mx-auto">
    <div class="row">
        <div class="col-10 col-lg-6 mx-auto card card-area">
            <h2>Adres</h2>
            <nz-divider></nz-divider>
            <a href="https://www.google.com/maps/place/%C3%96zg%C3%BCrNet/@38.4254797,27.1631234,14.92z/data=!4m9!1m2!2m1!1zw7Z6Z8O8ciBuZXQ!3m5!1s0x14bbd879998a654f:0x4a7443fe9d5f0322!8m2!3d38.4308173!4d27.1688657!15sCgvDtnpnw7xyIG5ldJIBEGNvcnBvcmF0ZV9vZmZpY2U"
               target="_blank">Halkapınar Teknik Malzeme İş Merkezi, 1348. Sk. No:5/303-A, 35110 Konak/İzmir</a>
        </div>
        <div class="col-10 col-lg-4 mx-auto card card-area">
            <h2>Telefon</h2>
            <a href="tel: +908509330414">08509330414</a>
            <nz-divider></nz-divider>
            <h2>E-mail</h2>
            <address>
                <a href="mailto:info@ozgurnet.com.tr">info@ozgurnet.com.tr</a>
            </address>
        </div>
        <div class="col-10 mx-auto card-area">
            <google-map class="map"
                        width="100%"
                        [options]="mapOptions">
                <map-marker [position]="marker.position"></map-marker>
            </google-map>
        </div>
    </div>

</div>