<div class="row">
    <div class="col-10 mx-auto card">
        <h2>Biz Kimiz?</h2>
        <nz-divider></nz-divider>
        <p style="text-indent: 20px;">
            Özgürnet 5 Ağustos 2015 tarihinde bilgisayar alt yapı sistemleri konusunda optimum çözümler sunmak üzere kuruldu. Bilişim sektöründe edindiğimiz 30 yıllık bilgi ve tecrübemize güvenerek çıktığımız bu yolda, sektörlerinde lider bir çok firmanın ihtiyaçlarını karşılayan onlarca başarılı projeye imza atmanın gururunu yaşıyoruz. Bilgi ve iletişim sektöründe, bilgi güvenliği ve iletişim performansı konusunda uçtan uca anahtar teslim projelerimizle şirketlerin alt yapı performanslarını artırıyor ve güvenilir hale getiriyoruz. Proje bittikten sonra da sizi yalnız bırakmıyor, memnuniyetiniz için çalışmaya devam ediyoruz.
        </p>
    </div>
    <div class="col-10 mx-auto">
        <div class="row">
            <div class="col-12 col-lg-5 card mx-auto">
                <h2>
                    Vizyonumuz
                </h2>
                <nz-divider></nz-divider>
                <p style="text-indent: 20px;">
                    Sizlere A’dan Z’ye kaliteli hizmet sunarak, hiç durmadan gelişen teknolojinin ışığında gün geçtikte büyüyerek sektörde lider olmayı amaçlıyoruz. Bölgemizdeki teknoloji ihtiyaçlarını öngörerek üst düzey teknoloji ve teknik hizmet verebileceğimize olan inancımız tam. Müşterileri haklarına daima saygılı, müşterileri tarafından takdir edilen, yenilikçi ve çalışanlar tarafından aranılan bir firma olma hedefimizden sapmadan yolumuza devam edeceğiz. Örnek bir firma olmak için yapılması gerekenleri biliyor ve planlarımızı buna göre yapıyoruz
                </p>
            </div>
            <div class="col-0 col-lg-2"></div>  
            <div class="col-12 col-lg-5 card mx-auto">
                <h2>
                    Misyonumuz
                </h2>
                <nz-divider></nz-divider>
                <p style="text-indent: 20px;">
                    Teknoloji dünyasının yönetsel ve bilimsel gelişmeleri ışığında kendimizi de geliştiriyoruz. Memnuniyetinize öncelik vererek alt yapı sistemleri, elektronik güvenlik sistemleri ve kontrol sistemleri konusunda çözüm odaklı projelerimizle siz değerli müşterilerimizin ihtiyaçlarını karşılamayı hedefliyoruz.
                </p>
            </div>
        </div>
    </div>
</div>