<div class="container-fluid">
    <div class="row">
        <ng-container *ngFor="let card of cardlarBurayaOzgur">
            <div class="card col-10 col-md-5">
                <div>
                    <div class="row">
                        <div class="col-4">
                            <img [src]="card.image"
                                 width="100%">
                        </div>
                        <div class="col-8">
                            <div class="description-area">
                                <div class="title-area">
                                    <div class="title font-weight-bold mt-2">
                                        {{card.title}}
                                    </div>
                                    <div class="description font-weight-light">
                                        <p>
                                            {{card.description}}
                                        </p>
                                    </div>
                                </div>
                                <div class="price-area">
                                    <a href="tel:+908509330414"> <i nz-icon
                                           nzType="phone"
                                           nzTheme="outline"></i>&nbsp;{{card.price}}'den almak için iletişime geçin.
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>