import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {


  innerWidth: number;

  
  images = [

    {
      path: 'assets/kapak1.png'
    },
    {
      path: 'assets/kapak2.png'
    },
    {
      path: 'assets/kapak3.png'
    },
    {
      path: 'assets/kapak4.png'
    },
    {
      path: 'assets/kapak5.png'
    }

  ]

  constructor() {
   }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }
  
}
