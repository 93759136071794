<div *ngIf="innerWidth > 576"
     class="carousel-area">
    <nz-carousel nzAutoPlay>
        <div *ngFor="let image of images"
             class="image-content"
             nz-carousel-content>
            <img [src]="image.path"
                 width="100%">
        </div>
    </nz-carousel>
</div>
<div *ngIf="innerWidth < 576"
     class="carousel-area-m">
    <nz-carousel nzAutoPlay>
        <div *ngFor="let image of images"
             class="image-content"
             nz-carousel-content>
            <img [src]="image.path"
                 width="100%">
        </div>
    </nz-carousel>
</div>


<nz-divider [nzText]="heading"></nz-divider>
<ng-template #heading>
    <h2>Hizmetlerimiz &nbsp; <i nz-icon
           nzType="down"
           nzTheme="outline"></i></h2>
    <span class="sub-title"> Sizin İçin neler yapabiliriz? </span>
</ng-template>

<div class="row self-center">
    <div class="col-sm-12 col-lg-4 card mx-auto">
        <h2> Network-Altyapı Sistemleri</h2>
        <p>Uçtan uca Çözümler</p>
        <nz-divider></nz-divider>
        <ul>
            <li>
                Bakır ve Fiber Optik kablolama ve sonlandırmaları
            </li>
            <li>
                Aktif cihaz ( Switch, Router, Frewall ) montaj ve kurulum
            </li>
            <li>
                Noktadan noktaya telsiz köprü hizmetlerinin kurulması
            </li>
            <li>
                Veri hatları üzerinden yüksek kalitede görüntü ve ses hizmetlerinin taşınması
            </li>
            <li>
                IP / ISDN telefon santralleri
            </li>
            <li>
                Sistem Odası ( Rack Kabin, Yükseltilmiş Döşeme) yapımı
            </li>
        </ul>
    </div>

    <div class="col-12 col-lg-4 card mx-auto">
        <h2>Kontrol Sistemleri</h2>
        <p>Akıllı Çözümler</p>
        <nz-divider></nz-divider>
        <ul>
            <li>
                IP kamera ve izleme merkezi kurulumları
            </li>
            <li>
                Termal kamera çözümleri
            </li>
            <li>
                Gaz algılama ve kontrol sistemleri
            </li>
            <li>
                Yangın alarm ve söndürme(FM200) sistemleri
            </li>
            <li>
                Geçiş kontrol ve turnike sistemleri
            </li>
            <li>
                Güvenlik ve Kimlik doğrulama sistemleri
            </li>
            <li>
                Görüntülü kapı kontrol üniteleri
            </li>
            <li>
                Anons Sistemleri
            </li>
            <li>
                Akıllı bina donanım ve yazılım hizmetleri
            </li>
        </ul>
    </div>
</div>