import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firsat-urunleri',
  templateUrl: './firsat-urunleri.component.html',
  styleUrls: ['./firsat-urunleri.component.scss']
})
export class FirsatUrunleriComponent implements OnInit {

  cardlarBurayaOzgur = [
    // {
    //   image: 'assets/nvrhaikon.jpeg',
    //   title: 'HIKVISION DS-7732NI-K4 Kayıt Cihazı',
    //   price: '2500₺',
    //   description: '32 kanala kadar IP kamera bağlanabilir. H.265 + / H.265 / H.264 + / H.264 video formatlarının kodunu çözmeyi destekler. 8 kanala kadar @ 1080p kod çözme kapasitesi. 256 Mbps ye kadar yüksek gelen bant genişliği, IP kameraların bağlanabilmesini sağlar. 1 HDMI ve 1 VGA arabirimi: her iki arabirim de bağımsız video çıkışını destekler. '
    // },
    {
      image: 'assets/kablo.jpg',
      title: 'Corning 62.5/125 mm 12 Core Fiber Kablo',
      price: '3000₺',
      description: '1550 metre, Stok fazlası.',
    },
    {
      image: 'assets/Legrand/10603_180_adet.jpeg',
      title: 'Legrand 10603 İç Köşe',
      price: '25₺',
      description: 'Stok: 180 adet.'
    },
    {
      image: 'assets/Legrand/10611_190_adet.jpeg',
      title: 'Legrand 10611 Kavisli Kesme İç Köşe',
      price: '40₺',
      description: 'Stok : 190 adet.'
    },
    {
      image: 'assets/Legrand/10614_70_adet.jpeg',
      title: 'Legrand 10614 İç Dirsek 95°-120°',
      price: '80₺',
      description: 'Stok : 70 adet.'
    },
    {
      image: 'assets/Legrand/10622_50_adet.jpeg',
      title: 'Legrand 10622 Değişken Dış Köşe 60°-120°',
      price: '30₺',
      description: 'Stok : 50 adet.'
    },
    {
      image: 'assets/Legrand/10623_135_adet.jpeg',
      title: 'Legrand 10623 Dış Dirsek 65mm',
      price: '30',
      description: 'Stok : 135 adet.'
    },
    {
      image: 'assets/Legrand/10663_224_adet.jpeg',
      title: 'Legrand 10663 ',
      price: '70₺',
      description: 'Stok : 224 adet.'
    },
    {
      image: 'assets/Legrand/10686_50_adet.jpeg',
      title: 'Legrand 10686 Destek Kelepçesi',
      price: '20₺',
      description: 'Stok : 50 adet.'
    },
    {
      image: 'assets/Legrand/10687_80_adet.jpeg',
      title: 'Legrand 10687 Çubuk Kanal Güçlendirici',
      price: '20₺',
      description: 'Stok : 80 adet.'
    },
    {
      image: 'assets/Legrand/10688_35_adet.jpeg',
      title: 'Legrand 10688 Kanal Güçlendirici',
      price: '20₺',
      description: 'Stok : 35 adet.'
    },
    {
      image: 'assets/Legrand/10689_15_adet.jpeg',
      title: 'Legrand 10689 Bölme Birleştirici',
      price: '20₺',
      description: 'Stok : 15 adet.'
    },
    {
      image: 'assets/Legrand/10732_15_adet.jpeg',
      title: 'Legrand 10732 T Dirsek',
      price: '50₺',
      description: 'Stok : 15 adet.'
    },
    {
      image: 'assets/Legrand/10735_50_adet.jpeg',
      title: 'Legrand 10735 T Dirsek',
      price: '50₺',
      description: 'Stok : 50 adet.'
    },
    {
      image: 'assets/Legrand/10763_4_adet.jpeg',
      title: 'Legrand 10763 Açılı T Dirsek',
      price: '100₺',
      description: 'Stok : 4 adet.'
    },
    {
      image: 'assets/Legrand/10785_8_adet.jpeg',
      title: 'Legrand 10785 Dirsek',
      price: '50₺',
      description: 'Stok : 8 adet.'
    },
    {
      image: 'assets/Legrand/10802_10_adet.jpeg',
      title: 'Legrand 10802 Kapak Birleştirme Parçası',
      price: '10₺',
      description: 'Stok : 10 adet.'
    },
    {
      image: 'assets/Legrand/10804_90_adet.jpeg',
      title: 'Legrand 10804 Kapak Birleştirme Parçası',
      price: '15₺',
      description: 'Stok : 90 adet.'
    },
    {
      image: 'assets/Legrand/10913_10_adet.jpeg',
      title: 'Legrand 10913',
      price: '2500₺',
      description: 'Stok : 10 adet.'
    },
    {
      image: 'assets/Legrand/010992_40_adet.jpeg',
      title: 'Legrand 10992 Kaide ',
      price: '20₺',
      description: 'Stok : 40 adet.'
    },
    {
      image: 'assets/Legrand/10993_30_adet.jpeg',
      title: 'Legrand 10993 Kaide',
      price: '20₺',
      description: 'Stok : 30 adet.'
    },
    {
      image: 'assets/Legrand/010994_20_adet.jpeg',
      title: 'Legrand 10994 Değişken Dış Köşe',
      price: '35₺',
      description: 'Stok : 20 adet.'
    },
    {
      image: 'assets/Legrand/10996_30_adet.jpeg',
      title: 'Legrand 10996 Kaide',
      price: '30₺',
      description: 'Stok : 30 adet.'
    },
    {
      image: 'assets/Legrand/30211_70_adet.jpeg',
      title: 'Legrand 30211 Ayarlı Köşe',
      price: '10₺',
      description: 'Stok : 70 adet.'
    },
    {
      image: 'assets/Legrand/30224_20_adet.jpeg',
      title: 'Legrand 30224 T Dirsek',
      price: '10₺',
      description: 'Stok : 20 adet.'
    },
    {
      image: 'assets/Legrand/30273_7_adet.jpeg',
      title: 'Legrand 30273 Köşe',
      price: '20₺',
      description: 'Stok : 7 adet.'
    },
    {
      image: 'assets/Legrand/30370_6_adet.jpeg',
      title: 'Legrand 30370 Çift Galyon Çerçeve',
      price: '20₺',
      description: 'Stok : 6 adet.'
    },
    {
      image: 'assets/Legrand/30915_5_adet.jpeg',
      title: 'Legrand 30915 Köşe',
      price: '20₺',
      description: 'Stok : 5 adet.'
    },
    {
      image: 'assets/Legrand/31207_10_adet.jpeg',
      title: 'Legrand 31207 Kanal Sonlandırma Kapağı',
      price: '10₺',
      description: 'Stok : 10 adet.'
    },
    {
      image: 'assets/Legrand/31208_14_adet.jpeg',
      title: 'Legrand 31208 Kanal Sonlandırma Kapağı',
      price: '10₺',
      description: 'Stok : 14 adet.'
    },
    {
      image: 'assets/Legrand/33327_70_adet.jpeg',
      title: 'Legrand 33327 Değişken Bükey Köşe',
      price: '10₺',
      description: 'Stok : 70 adet.'
    },
    {
      image: 'assets/Legrand/33328_20_adet.jpeg',
      title: 'Legrand 33328 Değişken Bükey Köşe',
      price: '10₺',
      description: 'Stok : 20 adet.'
    },
    {
      image: 'assets/Legrand/33329_20_adet.jpeg',
      title: 'Legrand 33329 Merkez ve Köşe Bağlantıları için T Dirsek',
      price: '50₺',
      description: 'Stok : 20 adet.'
    },{
      image: 'assets/Legrand/89299_2_adet.jpeg',
      title: 'Legrand 89299 Plastik Gömme Zemin Kutusu',
      price: '300₺',
      description: 'Stok : 2 adet.'
    },{
      image: 'assets/Legrand/601832_1_adet.jpeg',
      title: 'Legrand 601832 Nemli Yer Kofrası',
      price: '450₺',
      description: 'Stok : 1 adet.'
    },

    

  ]

  constructor() { }

  ngOnInit(): void {
  }

}
