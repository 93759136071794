<nav class="navbar navbar-expand-lg">
   <div class="nav-container">
      <div class="brand-logo">
         <a routerLink="AnaSayfa">
            <img src="assets/logo.png"
                 alt=""
                 width="100px">
         </a>
      </div>
      <div *ngIf="innerWidth > 576; else dropdownTmpl"
           class="nav-items">
         <div>
            <a class="link"
               routerLink="AnaSayfa">Anasayfa</a>
            <a class="link"
               routerLink="BizKimiz">Biz Kimiz</a>
            <a class="link"
               routerLink="Referanslarımız">Referanslarımız</a>
            <a class="discount-link"
               routerLink="FırsatÜrünleri">Fırsat Ürünleri</a>
            <a class="link"
               routerLink="İletişim">İletişim</a>
         </div>
      </div>
      <ng-template #dropdownTmpl>
         <button nz-button
                 nz-dropdown
                 [nzDropdownMenu]="menu1"
                 nzPlacement="bottomRight">
            <i nz-icon
               nzType="menu"
               nzTheme="outline"></i>
         </button>
         <nz-dropdown-menu #menu1="nzDropdownMenu">
            <ul nz-menu>
               <li nz-menu-item><a class="link"
                     routerLink="AnaSayfa">Anasayfa</a></li>
               <li nz-menu-item><a class="link"
                     routerLink="BizKimiz">Biz Kimiz</a></li>
               <li nz-menu-item><a class="link"
                     routerLink="Referanslarımız">Referanslarımız</a></li>
               <li nz-menu-item><a class="discount-link"
                     routerLink="FırsatÜrünleri">Fırsat Ürünleri</a></li>
               <li nz-menu-item><a class="link"
                     routerLink="İletişim">İletişim</a></li>
            </ul>
         </nz-dropdown-menu>
      </ng-template>
   </div>
</nav>
<div class="container-fluid">
   <router-outlet></router-outlet>
</div>
<div class="footer text-center p-3">
   <p>ÖzgürNet © Copyright 2021 - Tüm hakları saklıdır.</p>
</div>
<nz-modal [(nzVisible)]="modalVisible"
          (nzOnCancel)="onCancel()"
          (nzOnOk)="onCancel()"
          nzWidth="70%">
   <ng-container>
      <div class="image-area">
         <img width="100%"
              [src]="popupImage">
      </div>
   </ng-container>
   <div *nzModalFooter
        style="text-align: center; padding:5px 5px 5px 5px;">
   </div>
</nz-modal>
<nz-modal [(nzVisible)]="mobileModalVisible"
          (nzOnCancel)="onCancel()"
          (nzOnOk)="onCancel()"
          nzWidth="100%">
   <ng-container>
      <div class="image-area">
         <img width="100%"
              [src]="mpopupImage">
      </div>
   </ng-container>
   <div *nzModalFooter
        style="text-align: center; padding:5px 5px 5px 5px;">
   </div>
</nz-modal>