<div class="brand-container">
    <nz-divider [nzText]="heading"></nz-divider>

    <ng-template #heading>
        <h1>Referanslarımız</h1>
    </ng-template>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/cms-logo.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/efe_logo.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/horoz.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/idç_logo.jpg">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/izdenzi.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/izmir_oto.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/sabaf.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/fersan.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/ivt.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/mpiosb.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/türkerler.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/sismak.png">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/sodru.gif">
    </div>
    <div class="brand-logo card">
        <img width="90%"
             src="assets/yüksel_3.png">
    </div>
</div>