import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { WhoAreWeComponent } from './who-are-we/who-are-we.component';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ReferanslarimizComponent } from './referanslarimiz/referanslarimiz.component';
import { FirsatUrunleriComponent } from './firsat-urunleri/firsat-urunleri.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ContactComponent } from './contact/contact.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { IconDefinition } from '@ant-design/icons-angular';
import { MenuOutline, PhoneOutline } from '@ant-design/icons-angular/icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
const icons: IconDefinition[] = [ MenuOutline, PhoneOutline ];

registerLocaleData(en);

export const routes: Routes = [
  {
    path: 'AnaSayfa',
    component: HomePageComponent
  },
  {
    path: 'BizKimiz',
    component: WhoAreWeComponent
  },
  {
    path: 'Referanslarımız',
    component: ReferanslarimizComponent
  },
  {
    path: 'FırsatÜrünleri',
    component: FirsatUrunleriComponent
  },
  {
    path: 'İletişim',
    component: ContactComponent
  },
  {
    path: '**',
    component: HomePageComponent
  }


];
@NgModule({
  declarations: [
    AppComponent,
    WhoAreWeComponent,
    HomePageComponent,
    ReferanslarimizComponent,
    FirsatUrunleriComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzTableModule,
    NzCarouselModule,
    NzDividerModule,
    NzBackTopModule,
    RouterModule.forRoot(routes),
    NzCardModule,
    NzIconModule,
    NzModalModule,
    GoogleMapsModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule.forRoot(icons),
    NgbModule
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }, {provide: LocationStrategy, useClass:HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
